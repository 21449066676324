import { createRouter, createWebHistory } from 'vue-router';
import FrontPage from '../views/FrontPage.vue';
import ContactPage from '../views/ContactPage.vue';
import ServicesPage from '../views/ServicesPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: FrontPage,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage,
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage,
  }
  // Add more routes as needed
];

// Route all unmatched URLs to Home
const catchAllRedirect = {
  path: '/:catchAll(.*)',
  redirect: '/',
};

// Add the catch-all redirect route to the routes array
routes.push(catchAllRedirect);

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If the user is navigating back or forward, restore the saved position
      return savedPosition;
    } else {
      // Otherwise, scroll to the top of the page
      return { left: 0, top: 0 };
    }
  }
});

export default router;

<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">{{ logo }}</component>
  </teleport>

  <!-- FrontPage -->
  <header class="bgimg w3-display-container w3-container">
    <div class="w3-display-middle w3-center" style="width:90%;">
      <p class="frontPageHeader w3-text-white">{{ $t('slogan') }}</p>
    </div>
    <!-- Localization buttons for development -->
    <!-- <div class="w3-display-topright w3-padding-large w3-xlarge">
      <button class="w3-button locBtn w3-hide-small" @click="changeLocale('en_US')" :class="{ activeLocaleBtn: isLocale('en_US') }">English</button>
      <button class="w3-button locBtn w3-hide-small" @click="changeLocale('fi_FI')" :class="{ activeLocaleBtn: isLocale('fi_FI') }">Finnish</button>
    </div> -->
  </header>

  <div class="w3-padding-32 w3-container">

    <div class="w3-content w3-row">
      <div class="spacer-64">
        <h1 class="">{{ $t('ourServices') }}</h1>
        <p>{{ $t('ourMission.intro') }} {{ $t('ourMission.content') }}</p>
        <p> {{ $t('ourMission.closing') }}</p>
        <RouterLink to="/services" class="w3-button w3-orange">{{ $t('ctaExploreSerices') }} &rarr;</RouterLink>
      </div>
      <div class="w3-twothird">
        <img src="../assets/studio-1-1260-60.jpg" :alt="$t('imageAltText.studio')"/>
      </div>
      <div class="w3-third">
        <p>{{ $t('homeContent.intro') }}</p>
      </div>
    </div>

    <div class="w3-row w3-content spacer-128">
      <h2 style="line-height: 130%;">{{ $t('homeContent.contentShort') }}</h2>
    </div>

    <div class="w3-row w3-content">
      <div class="w3-third">
        <h3>{{ $t('homeEditingHead') }}</h3>
      </div>
      <div class="w3-twothird">
        <LongSingleParagraph style="margin-top: 16px;"
        :intro="$t('homeEditingContent.intro')"
        :content="$t('homeEditingContent.content')"
        :closing="$t('homeEditingContent.closing')"/>
      </div>
    </div>
    <div class="w3-row w3-content">
      <div class="w3-third">
        <h3>{{ $t('homeMixingHead') }}</h3>
      </div>
      <div class="w3-twothird">
        <LongSingleParagraph style="margin-top: 16px;"
        :intro="$t('homeMixingContent.intro')"
        :content="$t('homeMixingContent.content')"
        :closing="$t('homeMixingContent.closing')"/>
      </div>
    </div>
    <div class="w3-row w3-content">
      <div class="w3-third">
        <h3>{{ $t('homeMasteringHead') }}</h3>
      </div>
      <div class="w3-twothird">
        <LongSingleParagraph style="margin-top: 16px;"
        :intro="$t('homeMasteringContent.intro')"
        :content="$t('homeMasteringContent.content')"
        :closing="$t('homeMasteringContent.closing')"/>
      </div>
    </div>

    <div class="w3-content w3-row spacer-64">
      <img src="../assets/nero-1-1260-60.jpg" :alt="$t('imageAltText.nero')" style="max-width: 100%;"/>
    </div>

    <div class="w3-row w3-content spacer-128">
      <h2 style="line-height: 130%;">{{ $t('homeContent.closing') }}</h2>
      <RouterLink to="/contact" class="w3-button w3-orange">{{ $t('ctaContactNow') }} &rarr;</RouterLink>
    </div>

  </div>
  
</template>

<script>
import { useSeoMeta, useHead } from 'unhead';
import { useI18n } from 'vue-i18n';

import LongSingleParagraph from '@/components/LongSingleParagraph.vue';

export default {
  name: "FrontPage",
  components: { LongSingleParagraph },
  computed: {
    isLocale() {
      return (locale) => this.$i18n.locale === locale;
    },
    logo() {
      return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://mammothmix." + process.env.VUE_APP_DOMAIN,
        "logo": "https://mammothmix." + process.env.VUE_APP_DOMAIN + "/logo.png",
        "email": "contact@mammothmix.com",
        "slogan": "Your Sound, Refined"
      });
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  },
  setup() {
    const { t, availableLocales } = useI18n();

    // Read these from env file
    const domain = process.env.VUE_APP_DOMAIN;
    const lang = process.env.VUE_APP_LANG;
    const locale = process.env.VUE_APP_LOCALE;

    useSeoMeta({
      title: t('frontPageMetaTitle'),
      ogTitle: t('frontPageMetaTitle'),
      ogType: "website",
      ogImage: `https://mammothmix.${domain}/logo.png`,
      ogImageAlt: 'Text logo for Mammoth Mix',
      ogImageWidth: 500,
      ogImageHeight: 500,
      ogUrl: `https://mammothmix.${domain}`,
      description: t('frontPageMetaDescription'),
      ogDescription: t('frontPageMetaDescription'),
      locale: locale,
      ogLocale: locale,
      ogLocaleAlternate: availableLocales,
      revisit: '7 days'
    });
    useHead({
      htmlAttrs: [{
        lang: lang
      }],
      link: [{
        rel: 'canonical', href: `https://mammothmix.${domain}`
      }]
    });
  },
  mounted() {
    document.dispatchEvent(new Event('render-complete')); 
  }
}
</script>
<style scoped>
  .bgimg {
    background-image: url('../assets/marshall_1-40.jpg');
    background-color: rgba(0, 0, 0, 0.3); /* The last value is opacity */
    background-blend-mode: multiply;
    min-height: 60vh;
    background-position: center;
    background-size: cover;
  }
</style>

import { createApp } from 'vue';
import App from './App.vue';
import '@/assets/css/w3.css'
import '@/assets/css/main-black.css'
import router from './router/index';
import { createI18n } from 'vue-i18n';
import { createHead } from 'unhead';

// eslint-disable-next-line no-unused-vars
const head = createHead();

// Set the locale from .env file
const locale = process.env.VUE_APP_LOCALE;

const i18n = createI18n({
  locale: locale,
  messages: {
    en_US: require('./locales/en_US.json'),
    fi_FI: require('./locales/fi_FI.json')
  },
  legacy: false
});

const app = createApp(App)
  .use(i18n)
  .use(router);
  
await router.isReady();
app.mount('#app');

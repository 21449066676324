<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">{{ breadcrumbList }}</component>
  </teleport>

  <!-- ContactPage -->
  <header class="bgimg w3-display-container w3-container w3-black w3-center">
    <div class="">
      <h1 class="pageHeader" v-html="$t('contactHead')"></h1>
    </div>
    <!-- Localization buttons for development -->
    <!-- <div class="w3-display-topright w3-padding-large w3-xlarge">
      <button class="w3-button locBtn w3-hide-small" @click="changeLocale('en_US')" :class="{ activeLocaleBtn: isLocale('en_US') }">English</button>
      <button class="w3-button locBtn w3-hide-small" @click="changeLocale('fi_FI')" :class="{ activeLocaleBtn: isLocale('fi_FI') }">Finnish</button>
    </div> -->
  </header>

  <div class="w3-padding-32 w3-container">
    <div class="w3-content">
      <p>{{ $t('contactContent.intro') }}  <a class="w3-text-orange" href="mailto:contact@mammothmix.com">contact@mammothmix.com</a></p>
      <p>{{ $t('contactContent.closing') }}</p>
    </div>
  </div>
  
</template>

<script>
import { useSeoMeta, useHead } from 'unhead';
import { useI18n } from 'vue-i18n';

export default {
  name: "ContactPage",
  computed: {
    isLocale() {
      return (locale) => this.$i18n.locale === locale;
    },
    breadcrumbList() {
      return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Mammoth Mix",
          "item": "https://mammothmix." + process.env.VUE_APP_DOMAIN
        },{
          "@type": "ListItem",
          "position": 2,
          "name": this.$t('navContact')
        }]
      });
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  },
  setup() {
    const { t, availableLocales } = useI18n();

    // Read these from env file
    const domain = process.env.VUE_APP_DOMAIN;
    const lang = process.env.VUE_APP_LANG;
    const locale = process.env.VUE_APP_LOCALE;

    useSeoMeta({
      title: t('contactPageMetaTitle'),
      ogTitle: t('contactPageMetaTitle'),
      ogType: "website",
      ogImage: `https://mammothmix.${domain}/logo.png`,
      ogImageAlt: 'Text logo for Mammoth Mix',
      ogImageWidth: 500,
      ogImageHeight: 500,
      ogUrl: `https://mammothmix.${domain}/contact`,
      description: t('contactPageMetaDescription'),
      ogDescription: t('contactPageMetaDescription'),
      locale: locale,
      ogLocale: locale,
      ogLocaleAlternate: availableLocales,
      revisit: '7 days'
    });
    useHead({
      htmlAttrs: [{
        lang: lang
      }],
      link: [{
        rel: 'canonical', href: `https://mammothmix.${domain}/contact`
      }]
    });
  },
  mounted() {
    document.dispatchEvent(new Event('render-complete')); 
  }
}
</script>
<style scoped>
  .bgimg {
    background: black;
    /* background-image: url('../assets/marshall_1-40.jpg');
    min-height: 60vh; */
    background-position: center;
    background-size: cover;
  }
</style>

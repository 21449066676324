<template>
    <p>{{ intro }} {{ content }} {{ closing }}</p>
</template>

<script>
export default {
  props: {
    intro: String,
    content: String,
    closing: String
  }
};
</script>

<template>
  <NavBar />
  <router-view></router-view>
  <FooterSection />
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterSection from './components/FooterSection.vue';

import { useSeoMeta } from 'unhead';
import { useI18n } from 'vue-i18n';

export default {
  name: 'App',
  components: {
    NavBar, FooterSection
  },
  setup() {
    const { t } = useI18n();
    useSeoMeta({
      description: t('frontPageMetaDescription')
    });
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<template>
  <!-- FooterSection -->
  <!-- Footer -->
  <footer class="w3-container w3-padding-64 w3-center w3-black">
    <div class="3-container">
      <div class="w3-content">
        <p class="w3-margin w3-center slogan w3-text-grey">Your sound, refined</p>
      </div>
    </div>
    <div class="w3-xlarge w3-padding-32">
      <!-- <i class="fa fa-facebook-official w3-hover-opacity"></i> -->
      <!-- <i class="fa fa-instagram w3-hover-opacity"></i>
      <i class="fa fa-snapchat w3-hover-opacity"></i>
      <i class="fa fa-pinterest-p w3-hover-opacity"></i>
      <i class="fa fa-twitter w3-hover-opacity"></i> -->
      <i class="fa fa-linkedin w3-hover-opacity"></i>
      <p>{{ $t('footerLinkOtherDomain.intro') }} <a class="w3-text-orange" :href="$t('footerLinkOtherDomain.linkHref')">{{ $t('footerLinkOtherDomain.linkText') }}</a></p>
    </div>
    <p class="copy w3-text-grey">&copy; Mikko Holappa 2023</p>
    <a href="humans.txt"><img style="opacity: 0.5;" src="../assets/humanstxt-transparent-color.png" alt="humans.txt" /></a>
  </footer>
  </template>

<script>
export default {
  name: 'FooterSection'
}
</script>

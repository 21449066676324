<template>
  <teleport to="head">
    <component :is="'script'" type="application/ld+json">{{ breadcrumbList }}</component>
  </teleport>

  <!-- ServicesPage -->
  <header class="bgimg w3-display-container w3-container">
    <div class="w3-display-middle w3-center" style="width:90%;">
      <h1 class="frontPageHeader w3-text-white" v-html="$t('servicesHead')"></h1>
    </div>
    <!-- Localization buttons for development -->
    <!-- <div class="w3-display-topright w3-padding-large w3-xlarge">
      <button class="w3-button locBtn w3-hide-small" @click="changeLocale('en_US')" :class="{ activeLocaleBtn: isLocale('en_US') }">English</button>
      <button class="w3-button locBtn w3-hide-small" @click="changeLocale('fi_FI')" :class="{ activeLocaleBtn: isLocale('fi_FI') }">Finnish</button>
    </div> -->
  </header>

  <div class="w3-padding-32 w3-container">

    <div class="w3-content w3-row spacer-64">
      <h2>{{ $t('servicesContent.intro') + " " + $t('servicesContent.content') + " " + $t('servicesContent.closing')}}</h2>
    </div>
    <!-- Editing -->
    <div class="w3-row w3-content spacer-64">
      <div class="w3-third">
        <h3>1. {{ $t('servicesEditingHead') }}</h3>
        <p class="price w3-text-orange">{{ $t('pricingStartingAt') }} 125€</p>
        <img src="../assets/editing.jpg" :alt="$t('imageAltText.editing')"/>
      </div>
      <div class="w3-twothird">
        <h4 style="margin-top: 16px;">{{ $t('servicesEditingContent.inlineHead') }}</h4>
        <p>{{ $t('servicesEditingContent.intro') }}</p>
        <ul class="w3-ul">
          <li>{{ $t('servicesEditingContent.listItem1') }}</li>
          <li>{{ $t('servicesEditingContent.listItem2') }}</li>
          <li>{{ $t('servicesEditingContent.listItem3') }}</li>
          <li>{{ $t('servicesEditingContent.listItem4') }}</li>
        </ul>
      </div>
      <div class="w3-row">
        <h4 style="padding-top: 30px;">{{ $t('sourceMaterial') }}</h4>
        <p>{{ $t('servicesEditingContent.inputFormats') }}</p>
      </div>  
      <div class="w3-row">
        <h4>{{ $t('servicesEditingWhatYouGetHead') }}</h4>
        <ul class="w3-ul">
          <li><b>{{ $t('servicesEditingWhatYouGetContent.listItem1Head') }}:</b><br/> {{ $t('servicesEditingWhatYouGetContent.listItem1Content') }}</li>
          <li><b>{{ $t('servicesEditingWhatYouGetContent.listItem2Head') }}:</b><br/> {{ $t('servicesEditingWhatYouGetContent.listItem2Content') }}</li>
          <li><b>{{ $t('servicesEditingWhatYouGetContent.listItem3Head') }}:</b><br/> {{ $t('servicesEditingWhatYouGetContent.listItem3Content') }}</li>
        </ul>
      </div>
    </div>
    <!-- Mixing -->
    <div class="w3-row w3-content spacer-64">
      <div class="w3-third">
        <h3>2. {{ $t('servicesMixingHead') }}</h3>
        <p class="price w3-text-orange">{{ $t('pricingStartingAt') }} 350€</p>
        <img src="../assets/mixing.jpg" :alt="$t('imageAltText.mixing')"/>
      </div>
      <div class="w3-twothird">
        <h4 style="margin-top: 16px;">{{ $t('servicesMixingContent.inlineHead') }}</h4>
        <p>{{ $t('servicesMixingContent.intro') }}</p>
        <ul class="w3-ul">
          <li>{{ $t('servicesMixingContent.listItem1') }}</li>
          <li>{{ $t('servicesMixingContent.listItem2') }}</li>
          <li>{{ $t('servicesMixingContent.listItem3') }}</li>
          <li>{{ $t('servicesMixingContent.listItem4') }}</li>
        </ul>
      </div>
      <div class="w3-row">
        <h4 style="padding-top: 30px;">{{ $t('sourceMaterial') }}</h4>
        <p>{{ $t('servicesMixingContent.inputFormats') }}</p>
      </div>
      <div class="w3-row">
        <h4 style="padding-top: 30px;">{{ $t('sercicesMixingPluginsHead') }}</h4>
        <p>{{ $t('sercicesMixingPluginsContent.intro') }}</p>
        <ul class="w3-ul">
          <li>{{ $t('sercicesMixingPluginsContent.listItem1') }}</li>
          <li>{{ $t('sercicesMixingPluginsContent.listItem2') }}</li>
          <li>{{ $t('sercicesMixingPluginsContent.listItem3') }}</li>
        </ul>
        <p>{{ $t('sercicesMixingPluginsContent.listClosing') }}</p>
      </div>
      <div class="w3-row">
        <h4>{{ $t('servicesMixingWhatYouGetHead') }}</h4>
        <ul class="w3-ul">
          <li>
            <b>{{ $t('servicesMixingWhatYouGetContent.listItem1Head') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.listItem1Content') }}
            <ul class="w3-ul">
              <li><b>{{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionUncompressedHead') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionUncompressedDesc') }}</li>
              <li><b>{{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionMP3Head') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionMP3Desc') }}</li>
              <li><b>{{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionInstrumentalHead') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionInstrumentalDesc') }}</li>
              <li><b>{{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionAcapellaHead') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.mixedTrackVersionAcapellaDesc') }}</li>
            </ul>
          </li>
          <li><b>{{ $t('servicesMixingWhatYouGetContent.listItem2Head') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.listItem2Content') }}</li>
          <li><b>{{ $t('servicesMixingWhatYouGetContent.listItem3Head') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.listItem3Content') }}</li>
          <li><b>{{ $t('servicesMixingWhatYouGetContent.listItem4Head') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.listItem4Content') }}</li>
          <li><b>{{ $t('servicesMixingWhatYouGetContent.listItem5Head') }}:</b><br/> {{ $t('servicesMixingWhatYouGetContent.listItem5Content') }}</li>
        </ul>
      </div>
    </div>
    <!-- Mastering -->
    <div class="w3-row w3-content spacer-64">
      <div class="w3-third">
        <h3>3. {{ $t('servicesMasteringHead') }}</h3>
        <p class="price w3-text-orange">{{ $t('pricingStartingAt') }} 80€</p>
        <img src="../assets/mastering.jpg" :alt="$t('imageAltText.mastering')"/>
      </div>
      <div class="w3-twothird">
        <h4 style="margin-top: 16px;">{{ $t('servicesMasteringContent.inlineHead') }}</h4>
        <p>{{ $t('servicesMasteringContent.intro') }}</p>
        <ul class="w3-ul">
          <li>{{ $t('servicesMasteringContent.listItem1') }}</li>
          <li>{{ $t('servicesMasteringContent.listItem2') }}</li>
          <li>{{ $t('servicesMasteringContent.listItem3') }}</li>
          <li>{{ $t('servicesMasteringContent.listItem4') }}</li>
        </ul>
      </div>
      <div class="w3-row">
        <h4 style="padding-top: 30px;">{{ $t('sourceMaterial') }}</h4>
        <p>{{ $t('servicesMasteringContent.inputFormats') }}</p>
      </div>
      <div class="w3-row">
        <h4>{{ $t('servicesMasteringWhatYouGetHead') }}</h4>
        <p>{{ $t('servicesMasteringWhatYouGetContent') }}</p>
      </div>
    </div>

    <div class="w3-content w3-row spacer-64">
      <img src="../assets/dspeaker-2-1260-60.jpg" :alt="$t('imageAltText.dspeaker')" style="max-width: 100%;"/>
    </div>
    <!-- Pricing -->
    <div class="w3-row w3-content spacer-64">
        <h2>{{ $t('ctaPricingHead') }}</h2>
        <p>{{ $t('ctaPricingContent.intro') }}</p>
        <p>{{ $t('ctaPricingContent.bundles') }}</p>
        <p><RouterLink to="/contact" class="w3-text-orange">{{ $t('ctaPricingContent.linkText') }}</RouterLink> {{ $t('ctaPricingContent.closing') }}</p>
    </div>
    <!-- Turnaround -->
    <div class="w3-row w3-content spacer-64">
        <h2>{{ $t('servicesTurnaroundHead') }}</h2>
        <p>{{ $t('servicesTurnaroundContent.intro') }} {{ $t('servicesTurnaroundContent.content') }} {{ $t('servicesTurnaroundContent.closing') }}</p>
    </div>
    <!-- cta -->
    <div class="w3-row w3-content spacer-64">
        <h2>{{ $t('ctaServicesHead') }}</h2>
        <p>{{ $t('ctaServicesContent.intro') }} <RouterLink to="/contact" class="w3-text-orange">{{ $t('ctaServicesContent.linkText') }}</RouterLink> {{ $t('ctaServicesContent.closing') }}</p>
    </div>
      
    <div class="w3-content w3-row spacer-64">
      <img src="../assets/genelec-1-1260-60.jpg" :alt="$t('imageAltText.genelec')" style="max-width: 100%;"/>
    </div>

  </div>
  
</template>

<script>
import { useSeoMeta, useHead } from 'unhead';
import { useI18n } from 'vue-i18n';

export default {
  name: "MixingPage",
  computed: {
    isLocale() {
      return (locale) => this.$i18n.locale === locale;
    },
    breadcrumbList() {
      return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Mammoth Mix",
          "item": "https://mammothmix." + process.env.VUE_APP_DOMAIN
        },{
          "@type": "ListItem",
          "position": 2,
          "name": this.$t('navServices')
        }]
      });
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
    }
  },
  setup() {
    const { t, availableLocales } = useI18n();

    // Read these from env file
    const domain = process.env.VUE_APP_DOMAIN;
    const lang = process.env.VUE_APP_LANG;
    const locale = process.env.VUE_APP_LOCALE;

    useSeoMeta({
      title: t('servicesPageMetaTitle'),
      ogTitle: t('servicesPageMetaTitle'),
      ogType: "website",
      ogImage: `https://mammothmix.${domain}/logo.png`,
      ogImageAlt: 'Text logo for Mammoth Mix',
      ogImageWidth: 500,
      ogImageHeight: 500,
      ogUrl: `https://mammothmix.${domain}/services`,
      description: t('servicesPageMetaDescription'),
      ogDescription: t('servicesPageMetaDescription'),
      locale: locale,
      ogLocale: locale,
      ogLocaleAlternate: availableLocales,
      revisit: '7 days'
    });
    useHead({
      htmlAttrs: [{
        lang: lang
      }],
      link: [{
        rel: 'canonical', href: `https://mammothmix.${domain}/services`
      }]
    });
  },
  mounted() {
    document.dispatchEvent(new Event('render-complete')); 
  }
}
</script>
<style scoped>
  .bgimg {
    background-image: url('../assets/nero-1-40.jpg');
    background-color: rgba(0, 0, 0, 0.3); /* The last value is opacity */
    background-blend-mode: multiply;
    min-height: 60vh;
    background-position: center;
    background-size: cover;
  }
</style>

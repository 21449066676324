<template>
  <!-- Navbar -->
  <div class="w3-top">
    <div class="w3-bar w3-black w3-card w3-left-align w3-xlarge navigation">
      <img class="logoImg w3-left" src="../assets/logo-white.svg" alt="logo" />
      <a class="w3-bar-item w3-button w3-hide-medium w3-hide-large w3-right w3-padding-large w3-hover-white w3-large w3-black" href="javascript:void(0);" onclick="toggleMenu()" title="Toggle Navigation Menu">
        <span class="material-symbols-outlined" style="height: 36px;">menu</span>
      </a>
      <router-link to="/" :class="{ 'w3-black': $route.path === '/' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu(true)">{{ $t('navHome') }}</router-link>
      <router-link to="/services" :class="{ 'w3-white': $route.path === '/services' }" class="w3-bar-item w3-button w3-hide-small w3-padding-large">{{ $t('navServices') }}</router-link>
      <router-link to="/contact" :class="{ 'w3-white': $route.path === '/contact' }" class="w3-bar-item w3-button w3-hide-small w3-padding-large">{{ $t('navContact') }}</router-link>
      <a :href="$t('footerLinkOtherDomain.linkHref')" :title="$t('footerLinkOtherDomain.linkTitle')" class="w3-bar-item w3-button w3-hide-small w3-padding-large w3-right">
        {{ $t('footerLinkOtherDomain.lang') }}<span class="material-symbols-outlined" style="vertical-align: middle; padding-left: 10px;">logout</span>
      </a>
    </div>

    <!-- Navbar on small screens -->
    <div id="navBurger" class="w3-bar-block w3-white w3-hide w3-hide-large w3-hide-medium w3-large navigation">
      <router-link to="/services" :class="{ 'w3-gray': $route.path === '/services' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu()">{{ $t('navServices') }}</router-link>
      <router-link to="/contact" :class="{ 'w3-gray': $route.path === '/contact' }" class="w3-bar-item w3-button w3-padding-large" onclick="toggleMenu()">{{ $t('navContact') }}</router-link>
      <a :href="$t('footerLinkOtherDomain.linkHref')" class="w3-bar-item w3-button w3-padding-large">
        {{ $t('footerLinkOtherDomain.lang') }}<span class="material-symbols-outlined" style="vertical-align: middle; padding-left: 10px;">logout</span>
      </a>
    </div>
  </div>  

</template>

<script>
export default {
  name: 'NavBar'
}
</script>
